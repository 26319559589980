export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: '返回',
      COMING_SOON: '即将推出',
      SEND: '发送',
      DEPOSIT: '存款',
      SWAP: '交换',
      TRANSFER: '转账',
      INNER_BALANCE: '内部余额',
      BALANCE: '余额',
      TRANSACTION_HISTORY: '交易历史',
      SEND_TO_WALLET: '发送到钱包',
      WALLET_BALANCE: '钱包余额',
      TRANSFER_INNER_BALANCE: '转移到内部余额',
      TRANSFER_WALLET: '转移到钱包',
      TYPE:'類型',
      FROM:'從',
      TO:'到',
      STATUS:'地位', 
      VALUE:'價值',
      DATE:'Date',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'您還沒有任何交易',
      MORE_TRANSACTION:"更多交易",
      SENDING:"傳送",
      RECEIVING:"接收",
      INTERNAL_ADDRESS:"內部位址",
      EXTERNAL_RECEIVING:"外部接收",
      EXTERNAL_SENDING:"外部發送",
      INTERNAL_TRANSFER:"內部轉帳",
      EXTERNAL_TRANSFER:"外部轉移",
    },
  },
};
