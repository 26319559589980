export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Back',
      CONING_SOON: 'Coming soon',
      SEND: 'Send',
      DEPOSIT: 'Deposit',
      SWAP: 'Swap',
      TRANSFER: 'Transfer',
      INNER_BALANCE: 'Inner Balance',
      BALANCE: 'Balance',
      TRANSACTION_HISTORY: 'TRANSACTION HISTORY',
      SEND_TO_WALLET: 'Send to wallet',
      WALLET_BALANCE: 'Wallet balance',
      TRANSFER_INNER_BALANCE: 'Transfer to inner balance',
      TRANSFER_WALLET: 'Transfer to wallet',
      TYPE:'TYPE',
      FROM:'From',
      TO:'To',
      STATUS:'Status', 
      VALUE:'VALUE',
      DATE:'Date',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'You don\'t have any transactions yet',
      MORE_TRANSACTION:"More transaction",
      SENDING:"Sending",
      RECEIVING:"Receiving",
      INTERNAL_ADDRESS:"Internal address",
      EXTERNAL_RECEIVING:"External Receiving",
      EXTERNAL_SENDING:"External Sending",
      INTERNAL_TRANSFER:"Internal Transfer",
      EXTERNAL_TRANSFER:"External Transfer",
    },
  },
};
