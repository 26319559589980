export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'رجوع',
      CONING_SOON: 'قريبًا',
      SEND: 'إرسال',
      DEPOSIT: 'إيداع',
      SWAP: 'تبديل',
      TRANSFER: 'نقل',
      INNER_BALANCE: 'الرصيد الداخلي',
      BALANCE: 'الرصيد',
      TRANSACTION_HISTORY: 'سجل المعاملات',
      SEND_TO_WALLET: 'إرسال إلى المحفظة',
      WALLET_BALANCE: 'رصيد المحفظة',
      TRANSFER_INNER_BALANCE: 'نقل إلى الرصيد الداخلي',
      TRANSFER_WALLET: 'نقل إلى المحفظة',
      TYPE:'يكتب',
      FROM:'من',
      TO:'ل',
      STATUS:'حالة', 
      VALUE:'قيمة',
      DATE:'تاريخ',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'ليس لديك أي معاملات حتى الآن',
      MORE_TRANSACTION:"مزيد من المعاملات",
      SENDING:"إرسال",
      RECEIVING:"إستلام",
      INTERNAL_ADDRESS:"العنوان الداخلي",
      EXTERNAL_RECEIVING:"استقبال خارجي",
      EXTERNAL_SENDING:"الإرسال الخارجي",
      INTERNAL_TRANSFER:"نقل داخلي",
      EXTERNAL_TRANSFER:"نقل خارجي",
    },
  },
};
