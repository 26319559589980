export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Dos',
      CONING_SOON: 'À venir',
      SEND: 'Envoyer',
      DEPOSIT: 'Dépôt',
      SWAP: 'Échanger',
      TRANSFER: 'Transfert',
      INNER_BALANCE: 'Équilibre intérieur',
      BALANCE: 'Équilibre',
      TRANSACTION_HISTORY: 'HISTORIQUE DES TRANSACTIONS',
      SEND_TO_WALLET: 'Envoyer au portefeuille',
      WALLET_BALANCE: 'Solde du portefeuille',
      TRANSFER_INNER_BALANCE: 'Transfert vers l\'équilibre intérieur',
      TRANSFER_WALLET: 'Transférer vers le portefeuille',
      TYPE:'TAPER',
      FROM:'Depuis',
      TO:'À',
      STATUS:'Statut', 
      VALUE:'VALEUR',
      DATE:'Date',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'Vous n\'avez pas encore de transactions',
      MORE_TRANSACTION:"Plus de transactions",
      SENDING:"Envoi",
      RECEIVING:"Réception",
      INTERNAL_ADDRESS:"Adresse interne",
      EXTERNAL_RECEIVING:"Réception externe",
      EXTERNAL_SENDING:"Envoi externe",
      INTERNAL_TRANSFER:"Transfert interne",
      EXTERNAL_TRANSFER:"Transfert externe",
    },
  },
};
